<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
<!--      <a-form-model-item label="图标" prop="ico" >-->
<!--        <a-input v-model="form.ico" placeholder="请输入图标" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="图标" prop="ico" >
        <a-upload
          listType="picture-card"
          class="avatar-uploader"
          :showUploadList="false"
          :beforeUpload="beforeUpload"
          :customRequest="handleChange"
          v-model="form.ico"
        >
          <img v-if="form.ico" :src="form.ico" alt="图标"
               style="height:104px;max-width:300px"/>
          <div v-else>
            <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-model-item>


      <a-form-model-item label="标题" prop="title" >
        <a-input v-model="form.title" placeholder="请输入标题" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入备注" />
      </a-form-model-item>

      <a-form-model-item label="展示位置" prop="remark" >
          <a-select placeholder="请选择" v-model="form.type" >
            <a-select-option v-for="(d, index) in typeOptions" :key="index"
                             :value="d.value"
            >{{ d.label }}
            </a-select-option>
          </a-select>
      </a-form-model-item>

      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getWelfare, addWelfare, updateWelfare } from '@/api/driving-school/welfare'
import ossUpload from '@/utils/ossUpload'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      typeOptions:[
        {"value":1,"label":"报名"},
        {"value":2,"label":"峰会"}
      ],
      submitLoading: false,
      formTitle: '',
      uploadLoading: false,
      // 表单参数
      form: {
        id: null,

        ico: null,

        title: null,
        type: null,

        createTime: null,

        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    beforeUpload: function (file) {
      return new Promise((resolve, reject) => {
        var fileType = file.type;
        if (fileType.indexOf('image') < 0) {
          this.$message.warning('请上传图片');
          return reject(false);
        }
        resolve(true);
      });
    },
    handleChange(info) { //上传图片回调
      debugger
      let that = this;
      this.uploadLoading = true;

      ossUpload.ossUpload(info.file, function (fileName) {
        that.uploadLoading = false;
        console.log(fileName)
        that.form.ico = "https://ccjp.oss-cn-beijing.aliyuncs.com/"+fileName; //文件名
        console.log('1111', that.form.ico)
      });
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        ico: null,
        title: null,
        createTime: null,
        type: null,
        remark: null
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getWelfare({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateWelfare(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addWelfare(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
