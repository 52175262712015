import request from '@/utils/request'


// 查询报名福利列表
export function listWelfare(query) {
  return request({
    url: '/driving-school/driving-school-welfare/list',
    method: 'get',
    params: query
  })
}

// 查询报名福利分页
export function pageWelfare(query) {
  return request({
    url: '/driving-school/driving-school-welfare/page',
    method: 'get',
    params: query
  })
}

// 查询报名福利详细
export function getWelfare(data) {
  return request({
    url: '/driving-school/driving-school-welfare/detail',
    method: 'get',
    params: data
  })
}

// 新增报名福利
export function addWelfare(data) {
  return request({
    url: '/driving-school/driving-school-welfare/add',
    method: 'post',
    data: data
  })
}

// 修改报名福利
export function updateWelfare(data) {
  return request({
    url: '/driving-school/driving-school-welfare/edit',
    method: 'post',
    data: data
  })
}

// 删除报名福利
export function delWelfare(data) {
  return request({
    url: '/driving-school/driving-school-welfare/delete',
    method: 'post',
    data: data
  })
}
